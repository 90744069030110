import React from 'react'
import { Route, Routes } from "react-router-dom";

import Clientes from '../../Components/Admin/Clientes/Clientes';

/**
 * 
 * @export
 * @function RouterClientes
 * @description Router for Clientes routes 
 */
function RouterClientes(props) {
  return (
    <Routes>
      <Route path="" element={<Clientes {...props} />} />
    </Routes>
  )
}

export default RouterClientes